import { FAX_CAREPROVIDER_STATUS, FAX_SEARCH_TYPES } from "@recare/core/consts";
import { isFrance } from "@recare/core/model/config";
import {
  Auction,
  AuctionRequest,
  Careprovider,
  Patient,
} from "@recare/core/types";

export const isFaxSender = ({ auction }: { auction: Auction | undefined }) => {
  if (!auction) return false;
  if (!FAX_SEARCH_TYPES.includes(auction.search_type) || isFrance) return false;

  return true;
};

export const isFaxReceiver = (
  careprovider: Careprovider | null | undefined,
): boolean =>
  Boolean(
    FAX_CAREPROVIDER_STATUS.includes(careprovider?.status ?? -1) &&
      careprovider?.fax_number?.length &&
      careprovider?.receive_fax,
  );

export const activateFax = ({
  auction,
  careprovider,
  request,
}: {
  auction?: Auction;
  careprovider?: Careprovider | null;
  patient?: Patient;
  request?: AuctionRequest | null;
}): boolean => {
  const faxProvider = careprovider || request?.careprovider;
  const faxAuction = auction || request?.auction;

  if (!faxProvider || !faxAuction) return false;

  return isFaxSender({ auction: faxAuction }) && isFaxReceiver(faxProvider);
};

export const isFaxRequest = (request: AuctionRequest): boolean =>
  !!(request?.fax_sent || activateFax({ request }));

export const getNewRequests = ({
  newAuction,
  oldAuction,
}: {
  newAuction: Auction;
  oldAuction?: Auction;
}): AuctionRequest[] => {
  if (!oldAuction?.requests?.length) return newAuction?.requests || [];

  const oldRequests =
    oldAuction.requests.map((oldRequest) => oldRequest.id) || [];

  return (
    newAuction?.requests?.filter(
      (newRequest) => !oldRequests.includes(newRequest.id),
    ) || []
  );
};
